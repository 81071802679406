<template>
  <div>
    <nav class="navbar navbar-expand-sm bg-black py-1">
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- Left Side (Logo) -->
        <div class="d-flex align-items-center">
          <span class="brand-logo">
            <b-img
              v-if="skin === 'light'"
              :src="appLogoImage"
              width="180px"
              alt="logo"
            />
            <b-img v-else :src="appDarkLogoImage" alt="logo" width="180px" />
          </span>
        </div>
        <!-- Right Side (User Info, Avatar, Logout Button) -->
        <div class="d-flex align-items-center">
          <div class="user-nav d-sm-flex d-none align-items-center mr-2">
            <div class="text-right">
              <p class="user-name font-weight-bolder mb-0">
                {{ $store.state.app.user.fullname }}
              </p>
              <span class="user-status">( {{ currentWorkspaceName }} )</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              class="badge-minimal ml-1"
              badge-variant="success"
              :text="avatarText($store.state.app.user.fullname)"
            />
          </div>
          <dark-Toggler class="d-none d-lg-block" />
          <b-button
            variant="outline-primary"
            class="btn-sm ml-1"
            size="sm"
            @click="logout"
          >
            Logout
          </b-button>
        </div>
      </div>
    </nav>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-8 mt-5">
        <b-card>
          <PerformAttackSurfaceScan :isOnboarding="true" />
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { BCard, BImg, BButton, BAvatar } from "bootstrap-vue";
import PerformAttackSurfaceScan from "./AttackSurface/components/performAttackSurfaceScan.vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    VuexyLogo,
    BCard,
    PerformAttackSurfaceScan,
    BImg,
    BButton,
    BAvatar,
    DarkToggler,
  },
  data() {
    return {
      avatarText,
    };
  },
  computed: {
    currentWorkspaceName() {
      const workspaces = this.$store.state.app.workspaces;
      const currentOrgId = this.$store.state.app.org_id;
      const currentWorkspace = workspaces.find(
        (workspace) => workspace.tenant_uid === currentOrgId
      );
      return currentWorkspace ? currentWorkspace.name : "";
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("notification/resetNotifications");
      this.$store.dispatch("app/logout");
      if (this.$route.name != "login") this.$router.push("/login");
    },
  },

  setup() {
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app;
    const { skin } = useAppConfig();
    return {
      appName,
      appLogoImage,
      appDarkLogoImage,
      skin,
    };
  },
};
</script>
<style scoped>
.bg-black {
  background: #fff !important;
}

[dir] .dark-layout .bg-black {
  background-color: #1e1e1e !important;
}
</style>
